import React from 'react';
import { settings } from '../data/site-options';
import greenGifts from "../images/green-gifts.png";
import giftBag from "../images/gift-bag.png";
import title from "../images/title-multiply-your-chances.png";
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'
export const MultiplyYourChances = (props) => {

  const lang = props.lang ? props.lang : "eng";
  const text = lang === "cn" 
               ? settings.text.cn.multiply.body 
               : settings.text.eng.multiply.body
  return (
    <>
      <section className='bg-gradient-purple'>
        <div className="container">
          <div className="img-float-top-right">
              <img src={giftBag} alt="a gift bag" />
          </div>
          <div className="title">
          <Slide left wait={2000}>
            <img src={title} alt="section title"/>
          </Slide>
          </div>
          <div className="text-box bg-box bg-box-red">
            <div className="ztop">
              <Fade>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </Fade>
            </div>
          </div>
        </div>
        <div className="cutter">
        </div>
        <div className="float-bottom-center">
          <Slide bottom>
            <img src={greenGifts} alt="green gifts" />
          </Slide>
        </div>
      </section>
    </>
  )
}
