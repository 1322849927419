import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Slide from 'react-reveal/Slide'
import Zoom from 'react-reveal/Zoom'

export const Offers = (props) => {
return (<section className='offers bg-gradient-purple'>
    <div className="title">
      <Slide right>
        <StaticImage src="../images/title-special-offers.png" alt='section Special Offers title'/>
      </Slide>
    </div>
    <div className='offers-grid'>
      <Zoom cascade duration={1000}>
        <div className="offer"><a href='https://myeushop.com/'><StaticImage src="../images/offers/bundle-1.png" alt="product bundle offer illustration"/></a></div>
        <div className="offer"><a href='https://myeushop.com/'><StaticImage src="../images/offers/bundle-3.png" alt="product bundle offer illustration"/></a></div>
        <div className="offer"><a href='https://myeushop.com/'><StaticImage src="../images/offers/bundle-4.png" alt="product bundle offer illustration"/></a></div>
        <div className="offer"><a href='https://myeushop.com/'><StaticImage src="../images/offers/bundle-5.png" alt="product bundle offer illustration"/></a></div>
        <div className="offer"><a href='https://myeushop.com/'><StaticImage src="../images/offers/bundle-6.png" alt="product bundle offer illustration"/></a></div>
        <div className="offer"><a href='https://myeushop.com/'><StaticImage src="../images/offers/bundle-7.png" alt="product bundle offer illustration"/></a></div>
        <div className="offer"><a href='https://myeushop.com/'><StaticImage src="../images/offers/bundle-10.png"alt="product bundle offer illustration" /></a></div>
        <div className="offer"><a href='https://myeushop.com/'><StaticImage src="../images/offers/bundle-11.png"alt="product bundle offer illustration" /></a></div>
      </Zoom>
    </div>
  </section>
  );
};
