import React from 'react';
import { settings } from '../data/site-options';
import headerImage from '../images/header-image.png';
import tree from '../images/christmas-tree.png';
import scrolldown from '../images/scroll-down.png';
import Zoom from 'react-reveal/Zoom'

export const Heroheader = (props) => {
  const lang = props.lang ? props.lang : "eng";
  const text = lang === "cn" 
               ? settings.text.cn.hero.body 
               : settings.text.eng.hero.body
 
  return (<>

    <div className="heroheader">
      <div className="content">
        <div className="text-box"></div>
        <div className="top">
          <Zoom duration={2000} wait={500}>
            <img src={headerImage} alt='main capaing brand'/>
          </Zoom>
          <div dangerouslySetInnerHTML={{ __html: text }} className="text"/>
          <div className='icon__scrolldown'>
            <img src={scrolldown} alt='a mouse - the object - illustration'/>
          </div>
        </div>
      </div>
      <div className="tree">
        <img src={tree} alt="christmas tree" />
      </div>
    </div>


  </>);
};
