import React from 'react'
import Helmet from 'react-helmet'

export const Layout = props => {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MyEUShop Prize Factory</title>
                <link rel="canonical" href="https://prizefactory.uxdir.com/" />
            </Helmet>
            <main>
                {props.children}
            </main>
        </>
    )
}