import React from 'react';
import { settings } from '../data/site-options';
import giftBundle from '../images/gifts-bundle.png';
import title from '../images/title-multiply-even-more.png';
import Slide from "react-reveal/Slide"

export const MultiplyEvenMore = (props) => {

  const lang = props.lang ? props.lang : "eng";
  const text = lang === "cn" 
               ? settings.text.cn.multiplyEvenMore.body 
               : settings.text.eng.multiplyEvenMore.body           
  return (
    <>
      <section className='bg-gradient-green'>
        <div className="container">
        <div className="title">
          <Slide wait={1000} right>
            <img src={title} alt="section title"/>
            </Slide>
          </div>
          <div className="text-box bg-box bg-box-green">
            <div dangerouslySetInnerHTML={{__html: text }} className='ztop'/>
          </div>
        </div>
        <div className="cutter">
        </div>
        <div className="float-bottom-center" style={{ bottom: "-24vw" }}>
          <Slide bottom>
            <img src={giftBundle} alt="a lot of gifts" />
          </Slide>
        </div>
      </section>
    </>
  )
}