import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Countdown from 'react-countdown';
import { settings } from '../data/site-options';
import title from '../images/title-stay-tuned.png';
import wechatCN from '../images/social-wechat.png';
import wechatEng from '../images/social-wechat-eng.png';

export const StayTuned = (props) => {

  const curDate = new Date();
  const expDate = new Date("2022-01-07T20:30:00");
  const expired = (curDate > expDate);

  const lang = props.lang ? props.lang : "eng";
  const text = lang === "cn" 
               ? settings.text.cn.stayTuned.body 
               : settings.text.eng.stayTuned.body    

  return (<section className='stay-tuned bg-gradient-red'>
    <div className="container">
      <div className="title mt-lg">
        <img src={title} alt="title" />
      </div>
      <div className="text">
        <div dangerouslySetInnerHTML={{__html: text}} />
        { !expired && <div className="countdown">  
          <p><strong>Check the next livestream</strong></p>
          <h2>
          <Countdown 
            date={'2022-01-07T19:30:03'}
            renderer = { (props) => { return(`${props.days} days ${props.hours}h ${props.minutes}m ${props.seconds}s`) }}  
          />
          </h2>
        </div> }
      </div>
        
      <div className="title wechat" style={{ margin: "2rem auto"}}>
        <a href="http://weixin.qq.com/r/syjL0-rEIqddrb6w932h" target="_blank" rel="noreferrer">
          { lang === "eng" && <img src={wechatEng} alt="Wechat button" /> }
          { lang === "cn" && <img src={wechatCN} alt="Wechat button" /> }
        </a>
      </div>
      <div className="title" style={{ margin: "2rem auto"}}>
        <StaticImage src='../images/qr-code-mes.png' alt="a qr code linking to site"/>
      </div>
    </div>
  </section>
  );
};
