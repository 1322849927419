import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { settings } from '../data/site-options.js';
import { Link } from 'gatsby';

export const Footer = (props) => {

  const lang = props.lang ? props.lang : "eng";

  return (<>
  { lang === "eng" && <div className='footer lang-eng'>
    <div className="links">
      <div className="social">
        <h2><span>{settings.text.eng.footer.follow}</span></h2>
        <div className="social-links">
          <a href={settings.social.tiktok} target="_blank" rel="noreferrer">
            <StaticImage src='../images/social-tiktok.png' alt="social network icon"/>
          </a>
          <a href={settings.social.facebook} target="_blank" rel="noreferrer">
            <StaticImage src='../images/social-facebook.png' alt="social network icon"/>
          </a>
          <a href={settings.social.instagram} target="_blank" rel="noreferrer">
            <StaticImage src='../images/social-instagram.png' alt="social network icon"/>
          </a>
        </div>
      </div>
      <div className="logo">
        <a href={settings.mes_eng} target="_blank" rel="noreferrer">
            <StaticImage src='../images/mes-logo.png' alt="social network icon"/>
        </a>
      </div>
      <div className='footer-menu'>
        <a href={settings.links.eng.agreement.url} className='link-item'>
          {settings.links.eng.agreement.title}
        </a>
        <Link to={settings.links.eng.rules.url} className='link-item'>
          {settings.links.eng.rules.title}
        </Link>
        <a href={settings.links.eng.download.url} className='link-item'>
          {settings.links.eng.download.title}
        </a>
        <a href={settings.links.eng.store.url} className='link-item'>
          {settings.links.eng.store.title}
        </a>        
      </div>
    </div>
    <div className="last-message">
      <small>{ settings.text.eng.footer.copyright }</small>
    </div>
  </div> }

  { lang === "cn" && <div className='footer lang-cn'>

    <div className="links">
      <div className="social">
        <h2><span>{settings.text.cn.footer.follow}</span></h2>
        <div className="social-links">
          <a href={settings.social.tiktok} target="_blank" rel="noreferrer">
            <StaticImage src='../images/social-tiktok.png' alt="social network icon"/>
          </a>
          <a href={settings.social.facebook} target="_blank" rel="noreferrer">
            <StaticImage src='../images/social-facebook.png' alt="social network icon"/>
          </a>
          <a href={settings.social.instagram} target="_blank" rel="noreferrer">
            <StaticImage src='../images/social-instagram.png' alt="social network icon"/>
          </a>
        </div>
      </div>
      
      <div className="logo">
       <a href={settings.mes_cn} target="_blank" rel="noreferrer">
            <StaticImage src='../images/mes-logo.png' alt="company logo"/>
          </a>
      </div>

      <div className='footer-menu'>
        <a href={settings.links.cn.agreement.url} className='link-item'>
          {settings.links.cn.agreement.title}
        </a>
        <Link to={settings.links.cn.rules.url} className='link-item'>
          {settings.links.cn.rules.title}
        </Link>
        <a href={settings.links.cn.download.url} className='link-item'>
          {settings.links.cn.download.title}
        </a>
        <a href={settings.links.cn.store.url} className='link-item'>
          {settings.links.cn.store.title}
        </a>        
      </div>

    </div>
    <div className="last-message">
      <small>{ settings.text.cn.footer.copyright }</small>
    </div>
  </div> }
  </>);
};
