export const settings = {
    mes_eng: "https://www.myeushop.com/",
    mes_cn: "https://www.myeushop.com/cn/",
    social: {
        facebook: "https://www.facebook.com/myEUshop",
        tiktok: "https://www.tiktok.com/@myeushop.com?",
        wechat: "https://wechat.com",
        instagram: "https://www.instagram.com/myeushop_official/"
    },
    links: {
        eng: {
            agreement: {
                title: "User Agreement",
                url: "https://www.myeushop.com/agreement"
            },
            rules: {
                title: "Promotion Rules",
                url: "/rules/"
            },
            download: {
                title: "Download our App",
                url: "https://play.google.com/store/apps/details?id=nl.nlebv.app.mall"
            },
            store: {
                title: "Visit our store",
                url: "https://www.myeushop.com/"
            }
        },
        cn: {
            agreement: {
                title: "用户协议",
                url: "https://www.myeushop.com/cn/agreement"
            },
            rules: {
                title: "活动规则",
                url: "/"
            },
            download: {
                title: "下载我们的应用",
                url: "https://play.google.com/store/apps/details?id=nl.nlebv.app.mall"
            },
            store: {
                title: "访问我们的网店",
                url: "https://www.myeushop.com/cn/"
            }
        }
    },
    text: {
        eng: {
            footer: {
                follow: "Follow Us",
                copyright: "This site and all it's content are copyrighed to MyEUShop and partners. If you want to copy, link, forward or refer to our content, make sure you have the propper authorization."
            },
            hero: {
                body: `<h1>Holidays?!</h1>
                <p>
                    It's time to win!<br />
                    Our Prize Factory starts! Do you want to win Ipads, notebooks, JBL speakers, and more incredible Gifts?!Hurry up! Dont miss the chance to win amazing gifts!</p>
                <h1>How to participate?</h1>
                <p>
                    It's fairly simple! <strong>Place a purchase order above € 99,00 between 22.12.2021 and 05.01.2022 </strong> After that, just cheer up! We will solve the rest!
                </p>`
            },
            multiply: {
                body: `<h1>Want to increase your chances to win?</h1>
                <p>
                    Every week, if you place a new purchase of 99 euros, on top of your previous ticket we will <strong>DOUBLE</strong> your chances.
                </p>
                <h2><strong>How this will work?</strong></h2>
                <p>
                    If on week one you have 1 purchase, you will have 1 chance to win.</p>
                    <p>On the week two, after placing a new order of €99 euros, we will add a ticket to win add to the pool. We will sum them and double it.</p>
                    <p>So, <strong>instead of 2 you will have 4 chances to win!</strong> And this will be multiplied by all the purchases above €99 euros you have during the promotion time.
                </p>
              `,
            },
            multiplyEvenMore: {
                body: `
                <h1>And about the 3rd week? </h1>
                <p>By there, we will</p>
                <h2><strong>TRIPLE YOUR CHANCES TO WIN!</strong></h2>
                <p>
                    The sky is the limit!!!!
                    Every week different exciting prizes and more chances to win!
                    Stay tuned and be ready for the best promotion you have ever seen!
                </p>`,
            },
            stayTuned: {
                body: `<h1>Have you placed your order above 99 Euros?</h1>
                <p>
                    We will send you a personalized newsletter to tell you that you are one of our candidates!
                </p>
                <p>
                    We will withdraw the winners in a random pickup, spread the news and let you know via email as well.
                </p>
                <p>
                    The announcement of the prizes and the winners of the week, will be available on our social media channels!
                </p>
          
                <h2><strong>27.12.2021</strong></h2>
                <p>
                    Instagram, Tiktok, Wechat and the newsletter.
                </p>
          
                <h2><strong>03.01.2022</strong> </h2>
                <p>
                    Instagram, Tiktok, Wechat and newsletter
                </p>
          
                <h2><strong>07.01.2022</strong>* </h2>
                <p>
                    Instagram, Tiktok, Wechat and newsletter
                </p>`,
                live: ""
            }

        },
        cn: {
            hero: {
                body:`
                <h2>
                    <span>
                        圣诞元旦，双旦假期！
                    </span>
                </h2>
                <h2>
                    <span>
                        欧亚商城，给你送礼！
                    </span>
                </h2>
                <p>
                    <span>
                        想赢得Ipad、笔记本电脑、<br/>
                        JBL 音箱等等精美礼品吗？
                    </span>
                    <span>
                        赶快行动起来！不要错过！<br/>
                    </span>
                    <strong>
                        <span>
                            怎么参与？非常简单!
                        <span>
                    </strong>
                </p>`
            },
            multiply: {
                body: `
                <p><span>12月22日到1月5日</span>，在欧亚商城下单超过99  欧，就有机会参与抽奖！</p>
                <p>想加倍您的获奖几率吗？如果在第一周你购买超过99 欧，将有1次获奖的机会。</p>
                <p>如果在第二周您再次下单超过99 欧元，我们将在奖池中再次加入你的客户编号，然后*2，这
                时整个奖池里会一共有您的客户编号4个，而不是2个!</p>
                `,
            },
            multiplyEvenMore: {
                body: `
                <h1>还想翻倍？没问题！</h1>
                <p>如果在第三周您第三次下单超过99 欧元，我们又将在奖池中加入你的客户编号，然后*3，这时整个奖池里会一共有您的客户编号12 个！</p>
                <br/>
                <p>还能提高中奖几率？当然！</p>
                <p>如果您在第一周就有2笔订单超过99 欧，那么在第一周抽奖开始之前，您的客户编号已经是4个！<br/>不用等到第二周再翻倍！您的客户编号在奖池中的数量是乘以您在促销期间所有超过 99 欧元的订单的总数！</p>
                `,
            },
            stayTuned: {
                body: `
                <h2>
                    <strong>
                        活动期间，只要您下单超过99 欧：
                    <strong>
                </h2>
                <p>
                    我们将向您发送邮件，通知您已经获得抽奖机会。
                </p>
                <p>
                    我们将随机抽取获奖者，并在以下日期在我们的社交媒体渠道上公布：
                </p>
                <h2>
                    <strong>
                        2021 年12 月27
                    </strong>
                </h2>
                <p>
                    日通过微信、邮件、<br/>Instagram 和Tiktok 公布。
                </p>
                <h2>
                    <strong>
                        2022 年1 月3
                    </strong>
                </h2>
                <p>
                    <span>
                        日通过微信、邮件、<br/>Instagram 和Tiktok 公布。
                    </span>
                </p>
                <h2>
                    <strong>
                        2022 年1 月7
                    </strong>
                </h2>
                <p>
                    日通过微信、邮件、<br/>Instagram 和Tiktok 公布。
                </p>
                `,
                live: ""
            },
            footer: {
                follow: "关注我们",
                copyright: ""
            }
        }
    }

}
